(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('registration', {
                parent: 'bannercreek',
                url: '/registration/:scrollTo?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'cerebroApp.registration.home.title'
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/registrations.html',
                        controller: 'RegistrationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'lastName,asc',
                        squash: true
                    },
                    search: null
                },
                onEnter: ['$location', '$stateParams', '$anchorScroll', '$timeout', function ($location, $stateParams, $anchorScroll, $timeout) {
                    $timeout(function() {
                        $location.hash($stateParams.scrollTo);
                        $anchorScroll()
                    }, 1000)
                }],
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registration');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('advertisement');
                        $translatePartialLoader.addPart('crowd');
                        $translatePartialLoader.addPart('roleDecision');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('registration-detail', {
                parent: 'registration',
                url: '/details/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'cerebroApp.registration.detail.title'
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/registration-detail.html',
                        controller: 'RegistrationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registration');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('advertisement');
                        $translatePartialLoader.addPart('crowd');
                        $translatePartialLoader.addPart('roleDecision');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Registration', function ($stateParams, Registration) {
                        return Registration.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'registration',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    backState: function () {
                        return {stateName: 'registration'};
                    }
                }
            })
            .state('registration-detail.project-list-select', {
                parent: 'registration-detail',
                url: '/{imageId}/project-list-select',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/project-list/project-list-select-dialog.html',
                        controller: 'ProjectListSelectionDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    projectList: null,
                                    selectedImageId: 0,
                                    availability: 'NOT_SET',
                                    status: 'NOT_SET',
                                    registration: null
                                };
                            },
                            image: ['Image', function (Image) {
                                return Image.get({id: $stateParams.imageId}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('registrationProjectList');
                                $translatePartialLoader.addPart('projectList');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                            $state.go('registration-detail');
                        }, function () {
                            $state.go('^');
                        });
                }]
            })
            .state('registration.new', {
                parent: 'registration',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/registration-dialog.html',
                        controller: 'RegistrationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'registration',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    entity: function () {
                        return {
                            gender: null,
                            lastName: null,
                            firstName: null,
                            birthName: null,
                            birthDate: null,
                            phone: null,
                            email: null,
                            permanentAddress: null,
                            nationality: null,
                            niNumber: null,
                            taxNumber: null,
                            motherName: null,
                            height: null,
                            weight: null,
                            chestSize: null,
                            waistSize: null,
                            hipSize: null,
                            clothesSize: null,
                            shoeSize: null,
                            neckSize: null,
                            headSize: null,
                            chestOff: null,
                            waistOff: null,
                            hipOff: null,
                            neckOff: null,
                            headOff: null,
                            advertisement: null,
                            crowd: null,
                            agent: null,
                            filmRole: null,
                            swimSuitRole: null,
                            halfNudeRole: null,
                            nudeRole: null,
                            tattoo: null,
                            piercing: null,
                            scar: null,
                            twins: null,
                            children: null,
                            availableRemark: null,
                            notAvailableRemark: null,
                            remark: null,
                            comment: null,
                            lobby: false,
                            id: null
                        };
                    }
                }
            })
            .state('registration.edit', {
                parent: 'registration',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/registration-dialog.html',
                        controller: 'RegistrationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Registration', '$stateParams', function (Registration, $stateParams) {
                        return Registration.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'registration',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('registration.quickedit', {
                parent: 'registration',
                url: '/{id}/quickedit',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/quick-registration-dialog.html',
                        controller: 'RegistrationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Registration', '$stateParams', function (Registration, $stateParams) {
                        return Registration.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'registration',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('registration.delete', {
                parent: 'registration',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/registration/registration-delete-dialog.html',
                        controller: 'RegistrationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Registration', function (Registration) {
                                return Registration.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                            $state.go('registration', null, {reload: 'registration'});
                        }, function () {
                            $state.go('^');
                        });
                }]
            })
            .state('registration-search', {
                parent: 'bannercreek',
                url: '/registration-search/:scrollTo?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'cerebroApp.registration.home.searchTitle'
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/registrations-search.html',
                        controller: 'RegistrationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'lastName,asc',
                        squash: true
                    },
                    search: null
                },
                onEnter: ['$location', '$stateParams', '$anchorScroll', '$timeout', function ($location, $stateParams, $anchorScroll, $timeout) {
                    $timeout(function() {
                        $location.hash($stateParams.scrollTo);
                        $anchorScroll()
                    }, 1000)
                }],
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registration');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('advertisement');
                        $translatePartialLoader.addPart('crowd');
                        $translatePartialLoader.addPart('roleDecision');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('registration-search.project-list-select', {
                parent: 'registration-search',
                url: '/{id}/project-list-select',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/project-list/project-list-select-dialog.html',
                        controller: 'ProjectListSelectionDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    projectList: null,
                                    selectedImageId: 0,
                                    availability: 'NOT_SET',
                                    status: 'NOT_SET',
                                    registration: null
                                };
                            },
                            image: ['Image', function (Image) {
                                return Image.get({id: $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('registrationProjectList');
                                $translatePartialLoader.addPart('projectList');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                            $state.go('registration-search');
                        }, function () {
                            $state.go('^');
                        });
                }]
            })
            .state('registration-search-detail', {
                parent: 'registration-search',
                url: '/details/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'cerebroApp.registration.detail.title'
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/registration-detail.html',
                        controller: 'RegistrationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registration');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('advertisement');
                        $translatePartialLoader.addPart('crowd');
                        $translatePartialLoader.addPart('roleDecision');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Registration', function ($stateParams, Registration) {
                        return Registration.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'registration',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    backState: function () {
                        return {stateName: 'registration-search'};
                    }
                }
            })
            .state('registration-search-detail.project-list-select', {
                parent: 'registration-search-detail',
                url: '/{imageId}/project-list-select',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/project-list/project-list-select-dialog.html',
                        controller: 'ProjectListSelectionDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    projectList: null,
                                    selectedImageId: 0,
                                    availability: 'NOT_SET',
                                    status: 'NOT_SET',
                                    registration: null
                                };
                            },
                            image: ['Image', function (Image) {
                                return Image.get({id: $stateParams.imageId}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('registrationProjectList');
                                $translatePartialLoader.addPart('projectList');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                            $state.go('registration-search-detail');
                        }, function () {
                            $state.go('^');
                        });
                }]
            });

    }

})();
