(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService'];

    function NavbarController ($state, Auth, Principal, ProfileService) {
        var vm = this;
        vm.$state = $state;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        Principal.identity().then(function(account) {
            vm.account = account;
            vm.isAuthenticated = Principal.isAuthenticated;
        });

    }
})();
