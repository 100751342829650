(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('MusicInstrumentDeleteController',MusicInstrumentDeleteController);

    MusicInstrumentDeleteController.$inject = ['$uibModalInstance', 'entity', 'MusicInstrument'];

    function MusicInstrumentDeleteController($uibModalInstance, entity, MusicInstrument) {
        var vm = this;

        vm.musicInstrument = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MusicInstrument.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
