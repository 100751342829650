(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('registration-history', {
                parent: 'registration',
                url: '/{registrationId}/registration-history?rhpage&rhsort',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'cerebroApp.registrationHistory.home.title'
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration-history/registration-histories.html',
                        controller: 'RegistrationHistoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    rhpage: {
                        value: '1',
                        squash: true
                    },
                    rhsort: {
                        value: 'created,desc',
                        squash: true
                    }
                },
                resolve: {
                    registration: ['$stateParams', 'Registration', function ($stateParams, Registration) {
                        return Registration.get({id: $stateParams.registrationId}).$promise;
                    }],
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.rhpage),
                            sort: $stateParams.rhsort,
                            predicate: PaginationUtil.parsePredicate($stateParams.rhsort),
                            ascending: PaginationUtil.parseAscending($stateParams.rhsort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registrationHistory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
