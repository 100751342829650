(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('RegistrationProjectListController', RegistrationProjectListController);

    RegistrationProjectListController.$inject = ['$scope', '$state', 'RegistrationProjectList', 'DateUtils',
        'RegistrationProjectListSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'projectList'];

    function RegistrationProjectListController ($scope, $state, RegistrationProjectList, DateUtils,
        RegistrationProjectListSearch, ParseLinks, AlertService, pagingParams, paginationConstants, projectList) {
        var vm = this;

        vm.projectList = projectList;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.back = back;
        vm.search = search;
        vm.setStatus = setStatus;
        vm.setAvailability = setAvailability;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.calculateAge = DateUtils.calculateAge;
        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                RegistrationProjectListSearch.query({
                    query: preProcessSearchQuery(pagingParams.search) + ' AND projectList.id: ' + vm.projectList.id,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                RegistrationProjectList.queryByProjectList({
                    projectListId: vm.projectList.id,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [(pagingParams.search || vm.predicate == 'status' || vm.predicate == 'availability'  || vm.predicate == 'created' ? '' : 'registration.') + vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.registrationProjectLists = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function preProcessSearchQuery(query) {
            var enumPatterns = [
                {key: '\\[férfi\\]', value: 'MALE'},
                {key: '\\[nő\\]', value: 'FEMALE'},
                {key: '\\[egyéb\\]', value: 'ELSE'},
                {key: '\\[igen\\]', value: 'YES'},
                {key: '\\[nem ér rá\\]', value: 'NOT_AVAILABLE'},
                {key: '\\[nem kell\\]', value: 'NOT_SELECTED'},
                {key: '\\[nem\\]', value: 'NO'},
                {key: '\\[talán\\]', value: 'MAYBE'},
                {key: '\\[csak kiemelt\\]', value: 'JUST_SPECIAL'},
                {key: '\\[csak külföldi\\]', value: 'JUST_FOREIGNER'},
                {key: '\\[ráér\\]', value: 'AVAILABLE'},
                {key: '\\[n/a\\]', value: 'NOT_SET'},
                {key: '\\[kiválasztva\\]', value: 'SELECTED'}
            ];

            var patterns = [
                {key: 'nem', value: 'registration.gender:'},
                {key: 'vezetéknév', value: 'registration.lastName:'},
                {key: 'keresztnév', value: 'registration.firstName:'},
                {key: 'születési név', value: 'registration.birthName:'},
                {key: 'születési idő', value: 'registration.birthDate:'},
                {key: 'telefon', value: 'registration.phone:'},
                {key: 'email', value: 'registration.email:'},
                {key: 'állandó lakcím', value: 'registration.permanentAddress:'},
                {key: 'állampolgárság', value: 'registration.nationality:'},
                {key: 'taj szám', value: 'registration.niNumber:'},
                {key: 'adóazonosító jel', value: 'registration.taxNumber:'},
                {key: 'anyja neve', value: 'registration.motherName:'},
                {key: 'magasság', value: 'registration.height:'},
                {key: 'súly', value: 'registration.weight:'},
                {key: 'mell körméret', value: 'registration.chestSize:'},
                {key: 'derék körméret', value: 'registration.waistSize:'},
                {key: 'csípő körméret', value: 'registration.hipSize:'},
                {key: 'konfekció méret', value: 'registration.clothesSize:'},
                {key: 'cipő méret', value: 'registration.shoeSize:'},
                {key: 'ingnyak méret', value: 'registration.neckSize:'},
                {key: 'kalap méret', value: 'registration.headSize:'},
                {key: 'film', value: 'registration.film:'},
                {key: 'reklám', value: 'registration.advertisement:'},
                {key: 'statisztálás', value: 'registration.crowd:'},
                {key: 'ügynök neve', value: 'registration.agent:'},
                {key: 'fürdőruhás jelenet', value: 'registration.swimSuitRole:'},
                {key: 'félakt', value: 'registration.halfNudeRole:'},
                {key: 'akt', value: 'registration.nudeRole:'},
                {key: 'tetoválás', value: 'registration.tattoo:'},
                {key: 'piercing', value: 'registration.piercing:'},
                {key: 'sebhely/műtéti heg', value: 'registration.scar:'},
                {key: 'egypetéjű ikertestvér', value: 'registration.twins:'},
                {key: 'gyerek', value: 'registration.children:'},
                {key: 'egyéb információ', value: 'registration.remark:'},
                {key: 'tényleges lakcím/város', value: 'registration.addressCities.name:'},
                {key: 'születési hely/ország', value: 'registration.birthCountry.name:'},
                {key: 'születési hely/város', value: 'registration.birthCity.name:'},
                {key: 'hajszín', value: 'registration.hairColor.name:'},
                {key: 'szemszín', value: 'registration.eyeColor.name:'},
                {key: 'etnikum', value: 'registration.race.name:'},
                {key: 'szemüveg', value: 'registration.glasses.name:'},
                {key: 'anyanyelv', value: 'registration.motherLanguage.name:'},
                {key: 'beszélt nyelv', value: 'registration.language1.name:'},
                {key: 'beszélt nyelv szintje', value: 'registration.languageLevel1.name:'},
                {key: 'hangszer', value: 'registration.musicInstruments.name:'},
                {key: 'sportok', value: 'registration.sports.name:'},
                {key: 'tánctudás', value: 'registration.dances.name:'},
                {key: 'címkék', value: 'registration.tags.name:'},
                {key: 'státusz', value: 'status:'},
                {key: 'elérhetőség', value: 'availability:'}
            ];

            for (var i = 0; i < patterns.length; i++) {
                if (query.toString().toLowerCase().indexOf('_exists_') >= 0) {
                    var regex = new RegExp(patterns[i].key.replace(' ', '(\\s*)') + '(\\s*)', 'ig');
                    query = query.replace(regex, patterns[i].value.substring(0, patterns[i].value.length - 1));
                }
                else {
                    var regex = new RegExp(patterns[i].key.replace(' ', '(\\s*)') + '(\\s*):', 'ig');
                    query = query.replace(regex, patterns[i].value);
                }
            }

            for (i = 0; i < enumPatterns.length; i++) {
                var regex = new RegExp(enumPatterns[i].key.replace(' ', '(\\s*)'), 'ig');
                query = query.replace(regex, enumPatterns[i].value);
            }
            console.log('preProcessedSearchQuery:' + query);
            return query;
        }


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                rplpage: vm.page,
                rplsort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                rplsearch: vm.currentSearch,
                projectListId: vm.projectList.id
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'lastName';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function back() {
            $state.go('project-list');
        }

        function setStatus (rpl, status) {
            rpl.status = status;
            RegistrationProjectList.update(rpl, onSuccess, onError);
            function onSuccess(data) {
                rpl.status = data.status;
                $state.transitionTo($state.$current, {
                    rplpage: vm.page,
                    rplsort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                    rplsearch: vm.currentSearch,
                    projectListId: vm.projectList.id
                }, {reload: 'registration-project-list'});
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function setAvailability(rpl, availability) {
            rpl.availability = availability;
            RegistrationProjectList.update(rpl, onSuccess, onError);
            function onSuccess(data) {
                rpl.availability = data.availability;
                $state.transitionTo($state.$current, {
                    rplpage: vm.page,
                    rplsort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                    rplsearch: vm.currentSearch,
                    projectListId: vm.projectList.id
                }, {reload: 'registration-project-list'});
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

    }
})();
