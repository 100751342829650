(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('registration-form', {
            parent: 'bannercreek',
            url: '/registration-form?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'cerebroApp.registrationForm.home.title'
            },
            views: {
                'content@base': {
                    templateUrl: 'app/entities/registration-form/registration-forms.html',
                    controller: 'RegistrationFormController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'created,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('registrationForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('registration-form-detail', {
            parent: 'bannercreek',
            url: '/registration-form/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'cerebroApp.registrationForm.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/registration-form/registration-form-detail.html',
                    controller: 'RegistrationFormDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('registrationForm');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'RegistrationForm', function($stateParams, RegistrationForm) {
                    return RegistrationForm.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'registration-form',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('registration-form-detail.edit', {
            parent: 'registration-form-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/registration-form/registration-form-dialog.html',
                    controller: 'RegistrationFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['RegistrationForm', function(RegistrationForm) {
                            return RegistrationForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('registration-form.new', {
            parent: 'registration-form',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/registration-form/registration-form-dialog.html',
                    controller: 'RegistrationFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                formId: null,
                                activated: true,
                                gender: false,
                                lastName: false,
                                firstName: false,
                                birthDate: false,
                                birthName: false,
                                phone: false,
                                email: false,
                                permanentAddress: false,
                                addressCities: false,
                                birthCountry: false,
                                birthCity: false,
                                motherName: false,
                                nationality: false,
                                niNumber: false,
                                taxNumber: false,
                                height: false,
                                weight: false,
                                chest: false,
                                neck: false,
                                waist: false,
                                hat: false,
                                hip: false,
                                hairColor: false,
                                clothesSize: false,
                                eyeColor: false,
                                shoeSize: false,
                                glasses: false,
                                motherLanguage: false,
                                language: false,
                                musicInstruments: false,
                                sports: false,
                                dances: false,
                                advertisement: false,
                                crowd: false,
                                film: false,
                                swimSuit: false,
                                halfNude: false,
                                nude: false,
                                agent: false,
                                tattoo: false,
                                piercing: false,
                                scar: false,
                                twins: false,
                                children: false,
                                remark: false,
                                availableRemark: false,
                                notAvailableRemark: false,
                                makePersistent: false,
                                formName: null,
                                created: null,
                                userName: null,
                                userLogin: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('registration-form', null, { reload: 'registration-form' });
                }, function() {
                    $state.go('registration-form');
                });
            }]
        })
        .state('registration-form.edit', {
            parent: 'registration-form',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/registration-form/registration-form-dialog.html',
                    controller: 'RegistrationFormDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['RegistrationForm', function(RegistrationForm) {
                            return RegistrationForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('registration-form', null, { reload: 'registration-form' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('registration-form.delete', {
            parent: 'registration-form',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/registration-form/registration-form-delete-dialog.html',
                    controller: 'RegistrationFormDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['RegistrationForm', function(RegistrationForm) {
                            return RegistrationForm.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('registration-form', null, { reload: 'registration-form' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('registration-form-online-delete', {
            parent: 'base',
            url: '/registration-form/online/delete/{id}',
            data: {
                authorities: [],
                pageTitle: 'cerebroApp.registrationForm.home.onlineFormTitle'
            },
            views: {
                'base@': {
                    templateUrl: 'app/entities/registration-form/online-registration-delete-form.html',
                    controller: 'OnlineRegistrationDeleteFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                registration: ['Registration', '$stateParams', function (Registration, $stateParams) {
                    return Registration.getByBannerCastingId({id: $stateParams.id}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('registrationForm');
                    return $translate.refresh();
                }]
            }
        })
        .state('registration-form-online', {
            parent: 'base',
            url: '/registration-form/online/{regFormId}',
            data: {
                authorities: [],
                pageTitle: 'cerebroApp.registrationForm.home.onlineFormTitle'
            },
            views: {
                'base@': {
                    templateUrl: 'app/entities/registration-form/online-registration-form.html',
                    controller: 'OnlineRegistrationFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                registrationForm: ['RegistrationForm', '$stateParams', function(RegistrationForm, $stateParams) {
                    return RegistrationForm.getByFormId({id : $stateParams.regFormId}, function (registrationForm) {
                        return registrationForm;
                    });
                }],
                registration: function () {
                    return {
                        bannerCastingId: null,
                        makeRegistrationPermanent: false,
                        gender: null,
                        formId: null,
                        lastName: null,
                        firstName: null,
                        birthName: null,
                        birthDate: null,
                        phone: null,
                        email: null,
                        permanentAddress: null,
                        nationality: null,
                        niNumber: null,
                        taxNumber: null,
                        motherName: null,
                        height: null,
                        weight: null,
                        chestSize: null,
                        waistSize: null,
                        hipSize: null,
                        clothesSize: null,
                        shoeSize: null,
                        neckSize: null,
                        headSize: null,
                        chestOff: null,
                        waistOff: null,
                        hipOff: null,
                        neckOff: null,
                        headOff: null,
                        advertisement: null,
                        crowd: null,
                        film: null,
                        agent: null,
                        filmRole: null,
                        swimSuitRole: null,
                        halfNudeRole: null,
                        nudeRole: null,
                        tattoo: null,
                        piercing: null,
                        scar: null,
                        twins: null,
                        children: null,
                        availableRemark: null,
                        notAvailableRemark: null,
                        remark: null,
                        images: [],
                        id: null
                    };
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('registration');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('advertisement');
                    $translatePartialLoader.addPart('crowd');
                    $translatePartialLoader.addPart('roleDecision');
                    $translatePartialLoader.addPart('registrationForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('registration-modify-online', {
            parent: 'base',
            url: '/registration-form/online/modify/{id}',
            data: {
                authorities: [],
                pageTitle: 'cerebroApp.registrationForm.home.onlineFormTitle'
            },
            views: {
                'base@': {
                    templateUrl: 'app/entities/registration-form/online-registration-form.html',
                    controller: 'OnlineRegistrationFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                registrationForm: function () {
                    return {
                        formId: 'registration-modification',
                        activated: true,
                        gender: true,
                        lastName: true,
                        firstName: true,
                        birthDate: true,
                        birthName: false,
                        phone: true,
                        email: true,
                        permanentAddress: true,
                        addressCities: true,
                        birthCountry: true,
                        birthCity: true,
                        motherName: true,
                        nationality: true,
                        niNumber: true,
                        taxNumber: true,
                        height: true,
                        weight: true,
                        chest: false,
                        neck: false,
                        waist: false,
                        hat: false,
                        hip: false,
                        hairColor: true,
                        clothesSize: true,
                        eyeColor: true,
                        shoeSize: true,
                        glasses: false,
                        motherLanguage: true,
                        language: true,
                        musicInstruments: true,
                        sports: true,
                        dances: true,
                        advertisement: true,
                        crowd: true,
                        film: true,
                        swimSuit: true,
                        halfNude: true,
                        nude: true,
                        agent: true,
                        tattoo: true,
                        piercing: true,
                        scar: false,
                        twins: true,
                        children: false,
                        remark: true,
                        availableRemark: false,
                        notAvailableRemark: false,
                        formName: 'Online regisztráció frissítés',
                        created: null,
                        userName: null,
                        userLogin: null,
                        id: 123456789
                    };
                },
                registration: ['Registration', '$stateParams', function (Registration, $stateParams) {
                    return Registration.getByBannerCastingId({id: $stateParams.id}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('registration');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('advertisement');
                    $translatePartialLoader.addPart('crowd');
                    $translatePartialLoader.addPart('roleDecision');
                    $translatePartialLoader.addPart('registrationForm');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
