(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('OnlineRegistrationFormController', OnlineRegistrationFormController);

    OnlineRegistrationFormController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'registration', 'registrationForm', 'Registration',
        'City', 'Country', 'HairColor', 'EyeColor', 'Glass', 'Language', 'LanguageLevel', 'MusicInstrument', 'Sport', 'Dance', 'DataUtils', 'DateUtils'];

    function OnlineRegistrationFormController ($timeout, $scope, $state, $stateParams, registration, registrationForm, Registration, City, Country, HairColor, EyeColor, Glass, Language, LanguageLevel, MusicInstrument, Sport, Dance, DataUtils, DateUtils) {
        var vm = this;

        vm.fullOnlineForm = $stateParams.regFormId === 'full';
        vm.modifyOnlineForm = registrationForm.formId === 'registration-modification';
        vm.bannerCastingIdEmail = null;
        vm.registrationLoadedByBannerCastingId = false;
        vm.registration = registration;
        vm.birthYear = null;
        vm.birthMonth = null;
        vm.birthDay = null;
        vm.submitSuccess = false;
        vm.submitFailure = false;
        vm.registrationForm = registrationForm;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getByBannerCastingId = getByBannerCastingId;
        vm.sendBannerCastingIdByEmail = sendBannerCastingIdByEmail;
        vm.resetDisableableField = resetDisableableField;
        vm.cities = City.queryNoPagination();
        vm.countries = Country.queryNoPagination();
        vm.haircolors = HairColor.queryNoPagination();
        vm.eyecolors = EyeColor.queryNoPagination();
        vm.glasses = Glass.queryNoPagination();
        vm.languages = Language.queryNoPagination();
        vm.languagelevels = LanguageLevel.queryNoPagination();
        vm.musicinstruments = MusicInstrument.queryNoPagination();
        vm.sports = Sport.queryNoPagination();
        vm.dances = Dance.queryNoPagination();
        vm.validFormUrl = validFormUrl;
        vm.isPersonalDataTitleVisible = isPersonalDataTitleVisible;
        vm.isMeasureTitleVisible = isMeasureTitleVisible;
        vm.isWillingnessTitleVisible = isWillingnessTitleVisible;
        vm.isLanguageTitleVisible = isLanguageTitleVisible;
        vm.isMiscTitleVisible = isMiscTitleVisible;
        vm.setImage = setImage;
        vm.byteSize = DataUtils.byteSize;
        vm.phonePattern = '[\\d]+';
        vm.shoeSizePattern = '^0$|\\d+[-,/]*\\d+';
        vm.clothesSizePattern = '^0$|\\d+[-,/]*\\d+';
        vm.images = [];
        vm.imageNames = [];
        vm.bannerCastingEmailNotificationMessage = null;
        vm.gdpr = false;

        initBirthSelectValues();

        // in case of online reg modification
        if (vm.registration.birthDate) {
            setSeparatedBirthDateValues(vm.registration.birthDate);
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('^');
        }

        function save () {
            vm.isSaving = true;
            vm.registration.formId = vm.fullOnlineForm ? 'registration-full-online' : vm.registrationForm.formId;
            vm.registration.images = vm.images;
            if (vm.birthYear && vm.birthMonth && vm.birthDay) {
                vm.registration.birthDate = new Date(vm.birthYear, vm.birthMonth.id - 1, vm.birthDay);
            }
            Registration.saveOnline(vm.registration, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.submitSuccess = true;
        }

        function onSaveError () {
            vm.isSaving = false;
            vm.submitFailure = true;
        }

        function sendBannerCastingIdByEmail () {
            Registration.sendBannerCastingIdByEmail({ lastName: null, firstName: null, email: vm.bannerCastingIdEmail, body: null },
                function (result) {
                    vm.bannerCastingEmailNotificationMessage = 'Sikeresen elküldtük az azonosítót a megadott email címre!';
                },
                function () {
                    vm.bannerCastingEmailNotificationMessage = 'A megadott email címhez nem tartozik regisztráció!';
                }
            );
        }

        function getByBannerCastingId(id) {
            Registration.getByBannerCastingId({id: id}).$promise.then(function (result) {
                vm.registration = result;
                setSeparatedBirthDateValues(vm.registration.birthDate);
                vm.registrationLoadedByBannerCastingId = true;
            }, function (error) {
                vm.registrationLoadedByBannerCastingId = false;
            });
        }

        vm.datePickerOpenStatus.birthDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function resetDisableableField(field, flag) {
            if (flag) {
                vm.registration[field] = null;
            }
        }

        function validFormUrl() {
            return vm.fullOnlineForm || (vm.registrationForm.id && vm.registrationForm.activated);
        }

        function isPersonalDataTitleVisible() {
            var form = vm.registrationForm;
            return vm.fullOnlineForm || form.gender || form.lastName || form.firstName || form.birthName || form.birthDate ||
                    form.phone || form.email || form.permanentAddress || form.nationality || form.niNumber || form.taxNumber ||
                    form.birthCountry || form.birthCity || form.motherName;
        }

        function isMeasureTitleVisible() {
            var form = vm.registrationForm;
            return vm.fullOnlineForm || form.height || form.weight || form.chest || form.waist || form.hip ||
                form.clothesSize || form.shoeSize || form.neck || form.hat || form.hairColor || form.eyeColor || form.glasses;
        }

        function isLanguageTitleVisible() {
            var form = vm.registrationForm;
            return vm.fullOnlineForm || form.motherLanguage || form.language || form.musicInstruments || form.sports || form.dances;
        }

        function isWillingnessTitleVisible() {
            var form = vm.registrationForm;
            return vm.fullOnlineForm || form.advertisement || form.crowd || form.swimSuit || form.halfNude || form.nude;
        }

        function isMiscTitleVisible() {
            var form = vm.registrationForm;
            return vm.fullOnlineForm || form.agent || form.tattoo || form.piercing || form.scar || form.twins ||
                form.children || form.remark;
        }

        function setImage($file, image) {
            if ($file && $file.$error === 'pattern') {
                return;
            }

            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        image.image = base64Data;
                        image.name = $file.name;
                        image.imageContentType = $file.type;
                    });
                });
            }
        }

        vm.dropzone = {};

        vm.dzAddedFile = function( file ) {
            var img = {
                image: null,
                imageContentType: null,
                imageOrder: 0,
                id: null,
                registration: {id: $stateParams.registrationId }
            };
            setImage(file, img);
            vm.images.push(img);
            vm.imageNames.push(file.name);
        };

        vm.dzError = function( file, errorMessage ) {
            AlertService.error(errorMessage);
        };

        vm.dzRemoveFile = function( file ) {
            for (var i = 0; i < vm.images.length; i++) {
                if (vm.imageNames[i] == file.name) {
                    $timeout(function () {
                        vm.imageNames.splice(i, 1);
                        vm.images.splice(i, 1);
                    });
                    break;
                }
            }
        };

        vm.dropzoneConfig = {
            dictDefaultMessage: 'Kattints ide a fájlfeltöltéshez...',
            dictInvalidFileType: 'Csak kép típusú fájlok tölthetők fel/Only image files can be uploaded',
            parallelUploads: 30,
            maxFilesize: 20,
            url: '#',
            autoProcessQueue: false,
            acceptedFiles: 'image/*',
            addRemoveLinks: true
        };

        function setSeparatedBirthDateValues(birthday) {
            if (!birthday) {
                return;
            }

            var parts = birthday.split('-');
            vm.birthYear = parts[0];
            vm.birthDay = parts[2];
            for (var i = 0; i < vm.birthSelectMonths.length; i++) {
                if (vm.birthSelectMonths[i].id === parts[1]) {
                    vm.birthMonth = vm.birthSelectMonths[i];
                    break;
                }
            }
        }

        function initBirthSelectValues() {
            vm.birthSelectYears = [];
            vm.birthSelectMonths = [];
            vm.birthSelectDays = [];

            for (var i = (new Date()).getFullYear(); vm.birthSelectYears.length <= 100; i--) {
                vm.birthSelectYears.push(i);
            }

            vm.birthSelectMonths.push({id: '01', name: 'Január/January'});
            vm.birthSelectMonths.push({id: '02', name: 'Február/February'});
            vm.birthSelectMonths.push({id: '03', name: 'Március/March'});
            vm.birthSelectMonths.push({id: '04', name: 'Április/April'});
            vm.birthSelectMonths.push({id: '05', name: 'Május/May'});
            vm.birthSelectMonths.push({id: '06', name: 'Június/June'});
            vm.birthSelectMonths.push({id: '07', name: 'Július/July'});
            vm.birthSelectMonths.push({id: '08', name: 'Augusztus/August'});
            vm.birthSelectMonths.push({id: '09', name: 'Szeptember/September'});
            vm.birthSelectMonths.push({id: '10', name: 'Október/October'});
            vm.birthSelectMonths.push({id: '11', name: 'November/November'});
            vm.birthSelectMonths.push({id: '12', name: 'December/December'});

            for (i = 1; i <= 31; i++) {
                vm.birthSelectDays.push(i.toString().length > 1 ? i.toString() : "0" + i.toString())
            }

        }
    }
})();
