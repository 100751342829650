(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('RegistrationSearchHelpDialogController', RegistrationSearchHelpDialogController);

    RegistrationSearchHelpDialogController.$inject = ['$timeout', '$uibModalInstance'];

    function RegistrationSearchHelpDialogController ($timeout, $uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
