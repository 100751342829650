(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('EmailDialogController', EmailDialogController);

    EmailDialogController.$inject = ['$timeout', '$scope', 'registrationEmail', '$uibModalInstance', 'Email'];

    function EmailDialogController ($timeout, $scope, registrationEmail, $uibModalInstance, Email) {
        var vm = this;

        vm.mail = { bannerCastingId: registrationEmail.bannerCastingId, lastName: registrationEmail.lastName, firstName: registrationEmail.firstName, email: registrationEmail.email, body: null };

        vm.summerNoteConfig = {
            height: 300,
            focus: true,
            toolbar: []
        };
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            Email.send(vm.mail, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('cerebroApp:emailSent', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
