(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('LobbyDialogController', LobbyDialogController);

    LobbyDialogController.$inject = ['$timeout', '$scope', '$state', 'entity', 'Registration', 'Lightbox',
        'City', 'Country', 'HairColor', 'EyeColor', 'Glass', 'Language', 'LanguageLevel', 'MusicInstrument', 'Sport', 'Tag', 'Dance', 'Lobby', 'Image', '$filter'];

    function LobbyDialogController ($timeout, $scope, $state, entity, Registration, Lightbox,
                                    City, Country, HairColor, EyeColor, Glass, Language, LanguageLevel, MusicInstrument, Sport, Tag, Dance, Lobby, Image, $filter) {
        var vm = this;

        vm.lobby = entity;
        vm.registration = vm.lobby.newReg;
        vm.oldReg = vm.lobby.oldReg;
        vm.clear = clear;
        vm.isFieldChanged = isFieldChanged;
        vm.isDateFieldChanged = isDateFieldChanged;
        vm.isBaseDataManyToOneFieldChanged = isBaseDataManyToOneFieldChanged;
        vm.isBaseDataManyToManyFieldChanged = isBaseDataManyToManyFieldChanged;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.cities = City.queryNoPagination();
        vm.countries = Country.queryNoPagination();
        vm.haircolors = HairColor.queryNoPagination();
        vm.eyecolors = EyeColor.queryNoPagination();
        vm.glasses = Glass.queryNoPagination();
        vm.languages = Language.queryNoPagination();
        vm.languagelevels = LanguageLevel.queryNoPagination();
        vm.musicinstruments = MusicInstrument.queryNoPagination();
        vm.sports = Sport.queryNoPagination();
        vm.tags = Tag.queryNoPagination();
        vm.dances = Dance.queryNoPagination();
        vm.images = [];
        vm.imageContents = {};
        vm.openLightboxModal = openLightboxModal;

        vm.isAdmin = true;
        vm.edit = false;

        loadImages();

        function loadImages() {
            Image.queryByRegistration({id: vm.registration.id}, function (result) {
                for (var i = 0; i < result.length; i++) {
                    vm.images.push({id: result[i].id, url: '/api/images/fullsize/' + result[i].id});
                }
                loadImagesContent();
            });

        }

        function loadImagesContent() {
            for (var i = 0; i  < vm.images.length; i++) {
                Image.get({id: vm.images[i].id}, function onSuccess(data) {
                    vm.imageContents[data.id] = data.image;
                });
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('lobby');
        }

        function save () {
            vm.isSaving = true;
            Lobby.approval(vm.lobby, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('cerebroApp:registrationUpdate', result);
            //vm.isSaving = false;
            if (vm.edit) {
                $state.go('lobby.edit', {id: result.id});
            }
            else {
                $state.go('lobby', null, {reload: 'lobby'});
            }
        }

        function onSaveError () {
            vm.isSaving = false;
            $state.go('lobby');
        }

        vm.datePickerOpenStatus.birthDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function isFieldChanged(field) {
            return vm.oldReg && vm.oldReg[field] && vm.oldReg[field] !== vm.registration[field];
        }

        function isDateFieldChanged(field) {
            return vm.oldReg && vm.oldReg[field] && $filter('date')(vm.oldReg[field], 'yyyy-MM-dd') != $filter('date')(vm.registration[field], 'yyyy-MM-dd');
        }

        function isBaseDataManyToOneFieldChanged(field) {
            return vm.oldReg && vm.oldReg[field] && vm.oldReg[field].id !== vm.registration[field].id;
        }

        function isBaseDataManyToManyFieldChanged(field) {
            if (vm.oldReg && vm.oldReg[field]) {
                var oldRegIds = [];
                var newRegIds = [];
                for (var i = 0; i < vm.oldReg[field].length; i++) {
                    oldRegIds.push(vm.oldReg[field][i].id);
                }
                for (i = 0; i < vm.registration[field].length; i++) {
                    newRegIds.push(vm.registration[field][i].id);
                }

                oldRegIds.sort(function sortNumber(a, b) {
                    return a - b;
                });

                newRegIds.sort(function sortNumber(a, b) {
                    return a - b;
                });

                return oldRegIds != newRegIds;
            }
            return false;
        }

        function openLightboxModal(index) {
            Lightbox.openModal(vm.images, index);
        }

    }
})();
