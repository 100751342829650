(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('ImageDialogController', ImageDialogController);

    ImageDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Image', 'AlertService'];

    function ImageDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Image, AlertService) {
        var vm = this;

        vm.image = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.images = [];
        vm.imageNames = [];

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            Image.save(vm.images, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('cerebroApp:imageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        function setImage($file, image) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        image.image = base64Data;
                        image.imageContentType = $file.type;
                    });
                });
            }
        }

        vm.dropzone = {};

        vm.dzAddedFile = function( file ) {
            var img = {
                image: null,
                imageContentType: null,
                imageOrder: 0,
                id: null,
                registration: {id: $stateParams.registrationId }
            };
            setImage(file, img);
            vm.images.push(img);
            vm.imageNames.push(file.name);
        };

        vm.dzError = function( file, errorMessage ) {
            AlertService.error(errorMessage);
        };

        vm.dzRemoveFile = function( file ) {
            for (var i = 0; i < vm.images.length; i++) {
                if (vm.imageNames[i] == file.name) {
                    $timeout(function () {
                        vm.imageNames.splice(i, 1);
                        vm.images.splice(i, 1);
                    });
                    break;
                }
            }
        };

        vm.dropzoneConfig = {
            dictDefaultMessage: 'Kattints ide a fájlfeltöltéshez...',
            parallelUploads: 30,
            maxFilesize: 20,
            autoProcessQueue: false,
            acceptedFiles: 'image/*',
            addRemoveLinks: true
        };


    }

})();
