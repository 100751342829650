(function() {
    'use strict';
    angular
        .module('cerebroApp')
        .factory('RegistrationHistory', RegistrationHistory);

    RegistrationHistory.$inject = ['$resource', 'DateUtils'];

    function RegistrationHistory ($resource, DateUtils) {
        var resourceUrl =  'api/registration-histories/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryByRegistration': {
                method: 'GET',
                url: 'api/registrations/:id/registration-histories',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
