(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('EyeColorDeleteController',EyeColorDeleteController);

    EyeColorDeleteController.$inject = ['$uibModalInstance', 'entity', 'EyeColor'];

    function EyeColorDeleteController($uibModalInstance, entity, EyeColor) {
        var vm = this;

        vm.eyeColor = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            EyeColor.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
