(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('OnlineRegistrationDeleteFormController', OnlineRegistrationDeleteFormController);

    OnlineRegistrationDeleteFormController.$inject = ['registration', 'Registration'];

    function OnlineRegistrationDeleteFormController (registration, Registration) {
        var vm = this;

        vm.registration = registration;

        save();

        function save () {
            Registration.deleteOnline({id: vm.registration.id}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.submitSuccess = true;
        }

        function onSaveError () {
            vm.submitFailure = true;
        }

    }
})();
