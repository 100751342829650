(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('ImageController', ImageController);

    ImageController.$inject = ['$scope', '$state', 'DataUtils', 'Image', 'registration'];

    function ImageController ($scope, $state, DataUtils, Image, registration) {
        var vm = this;

        vm.registration = registration;
        vm.images = [];
        vm.imageContents = {};
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.loadAll = loadAll;
        vm.downloadImage = downloadImage;
        vm.back = back;
        vm.rotateRight = rotateRight;
        vm.rotateLeft = rotateLeft;

        loadAll();

        function loadAll() {
            Image.queryByRegistration({id: vm.registration.id}, function (result) {
                vm.images = result;
                loadImages();
            });
        }

        function loadImages() {
            for (var i = 0; i  < vm.images.length; i++) {
                Image.get({id: vm.images[i].id}, function onSuccess(data) {
                    vm.imageContents[data.id] = data.image;
                });
            }
        }

        function save(image) {
            vm.isSaving = true;
            Image.update({id: image.id, imageContentType: image.imageContentType, imageOrder: image.imageOrder, registration: image.registration, image: vm.imageContents[image.id]}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            $scope.$emit('cerebroApp:imageUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        function downloadImage(imageId) {
            window.open('/api/images/download/' + imageId, '_blank');
        }

        function back() {
            $state.go('registration', {scrollTo: 'reg-' + vm.registration.id});
        }

        function rotateRight(image) {
            rotateImage(image, 90);
        }

        function rotateLeft(image) {
            rotateImage(image, 270);
        }

        function rotateImage(image, degree) {
            var img = document.createElement("img");
            img.src = 'data:' + image.imageContentType + ';base64,' + vm.imageContents[image.id];
            var canvas = document.createElement("canvas");
            var cContext = canvas.getContext('2d');

            var cw = img.width, ch = img.height, cx = 0, cy = 0;

            //   Calculate new canvas size and x/y coorditates for image
            switch(degree){
                case 90:
                    cw = img.height;
                    ch = img.width;
                    cy = img.height * (-1);
                    break;
                case 270:
                    cw = img.height;
                    ch = img.width;
                    cx = img.width * (-1);
                    break;
            }

            //  Rotate image
            canvas.setAttribute('width', cw);
            canvas.setAttribute('height', ch);
            cContext.rotate(degree * Math.PI / 180);
            cContext.drawImage(img, cx, cy);
            var imageBase64 = canvas.toDataURL(image.imageContentType);
            vm.imageContents[image.id] = imageBase64.substring(imageBase64.indexOf('base64,') + 'base64,'.length, imageBase64.length);
            save(image);
        }

    }
})();
