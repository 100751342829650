(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('DanceDeleteController',DanceDeleteController);

    DanceDeleteController.$inject = ['$uibModalInstance', 'entity', 'Dance'];

    function DanceDeleteController($uibModalInstance, entity, Dance) {
        var vm = this;

        vm.dance = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Dance.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
