(function() {
    'use strict';
    angular
        .module('cerebroApp')
        .factory('Registration', Registration);

    Registration.$inject = ['$resource', 'DateUtils'];

    function Registration ($resource, DateUtils) {
        var resourceUrl =  'api/registrations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.birthDate = DateUtils.convertLocalDateFromServer(data.birthDate);
                    }
                    return data;
                }
            },
            'getByBannerCastingId': {
                method: 'GET',
                url: '/api/registrations/banner-casting-id/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        // data.birthDate = DateUtils.convertLocalDateFromServer(data.birthDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
                    return angular.toJson(copy);
                }
            },
            'saveOnline': {
                method: 'POST',
                url: '/api/registrations/online',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.birthDate = DateUtils.convertLocalDateToServer(copy.birthDate);
                    return angular.toJson(copy);
                }
            },
            'deleteOnline': {
                method: 'DELETE',
                url: '/api/registrations/online/delete/:id'
            },
            'sendBannerCastingIdByEmail': {
                method: 'PUT',
                url: '/api/registrations/send-banner-casting-id-by-mail',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            }
        });
    }
})();
