(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .factory('RegistrationProjectListSearch', RegistrationProjectListSearch);

    RegistrationProjectListSearch.$inject = ['$resource'];

    function RegistrationProjectListSearch($resource) {
        var resourceUrl =  'api/_search/registration-project-lists/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
