(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('GlassDeleteController',GlassDeleteController);

    GlassDeleteController.$inject = ['$uibModalInstance', 'entity', 'Glass'];

    function GlassDeleteController($uibModalInstance, entity, Glass) {
        var vm = this;

        vm.glass = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Glass.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
