(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .directive('sortableimage', sortableimage);

    sortableimage.$inject = ['$timeout', 'Image'];

    function sortableimage($timeout, Image) {
        return {
            restrict: 'A',
            scope: {},
            link: function(scope, element) {
                $timeout(function() {
                    var $container = $(element).parents('.sortable-image-container');
                    $container.sortable({
                        update: function(event, ui) {
                            var $draggedItem = ui.item;
                            var $nextItem = $draggedItem.next();
                            //console.log('imageID: ' + $draggedItem.attr('data-image-id') + ' before ' + $nextItem.attr('data-image-id'));
                            Image.reorder({
                                id: $draggedItem.attr('data-image-id'),
                                beforeId: $nextItem.attr('data-image-id')
                            });
                        }
                    });
                });
            }
        };
    }
})();
