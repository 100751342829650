(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lobby', {
            parent: 'bannercreek',
            url: '/lobby?page&sort',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'cerebroApp.lobby.home.title'
            },
            views: {
                'content@base': {
                    templateUrl: 'app/entities/lobby/lobbies.html',
                    controller: 'LobbyController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'created,desc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('lobby');
                    $translatePartialLoader.addPart('registration');
                    $translatePartialLoader.addPart('gender');
                    $translatePartialLoader.addPart('advertisement');
                    $translatePartialLoader.addPart('crowd');
                    $translatePartialLoader.addPart('roleDecision');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('lobby.edit', {
            parent: 'lobby',
            url: '/{id}/registration-edit',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@base': {
                    templateUrl: 'app/entities/registration/registration-dialog.html',
                    controller: 'RegistrationDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Registration', '$stateParams', function (Registration, $stateParams) {
                    return Registration.get({id: $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'registration',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('lobby.approval', {
            parent: 'lobby',
            url: '/{id}/approval',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@base': {
                    templateUrl: 'app/entities/lobby/lobby-dialog.html',
                    controller: 'LobbyDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['Lobby', '$stateParams', function(Lobby, $stateParams) {
                    return Lobby.get({id : $stateParams.id}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('image');
                    return $translate.refresh();
                }]
            }
        })
        .state('lobby.delete', {
            parent: 'lobby',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lobby/lobby-delete-dialog.html',
                    controller: 'LobbyDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Lobby', function(Lobby) {
                            return Lobby.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lobby', null, { reload: 'lobby' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('lobby.erase', {
            parent: 'lobby',
            url: '/{id}/erase',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lobby/lobby-delete-dialog.html',
                    controller: 'LobbyEraseController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Lobby', function(Lobby) {
                            return Lobby.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lobby', null, { reload: 'lobby' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
