(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('RegistrationDetailController', RegistrationDetailController);

    RegistrationDetailController.$inject = ['$state', '$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Registration', 'City', 'Country',
        'HairColor', 'EyeColor', 'Glass', 'Language', 'LanguageLevel', 'MusicInstrument', 'Sport', 'Tag', 'Image', 'backState', 'Lightbox'];

    function RegistrationDetailController($state, $scope, $rootScope, $stateParams, previousState, entity, Registration, City, Country,
                                          HairColor, EyeColor, Glass, Language, LanguageLevel, MusicInstrument, Sport, Tag, Image, backState, Lightbox) {
        var vm = this;

        vm.registration = entity;
        vm.previousState = previousState.name;
        vm.imageContents = {};
        vm.images = [];
        vm.backState = backState;
        vm.back = back;
        vm.openLightboxModal = openLightboxModal;
        vm.downloadImage = downloadImage;
        vm.state = $state;

        Image.queryByRegistration({id: vm.registration.id}, function (result) {
            for (var i = 0; i < result.length; i++) {
                vm.images.push({id: result[i].id, url: '/api/images/fullsize/' + result[i].id});
            }
        });

        function back() {
            $state.go(vm.backState.stateName, {scrollTo: 'reg-' + vm.registration.id});
        }

        function openLightboxModal(index) {
            Lightbox.openModal(vm.images, index);
        }

        function downloadImage(imageId) {
            window.open('/api/images/download/' + imageId, '_blank');
        }

        var unsubscribe = $rootScope.$on('cerebroApp:registrationUpdate', function(event, result) {
            vm.registration = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
