(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('LobbyEraseController',LobbyEraseController);

    LobbyEraseController.$inject = ['$uibModalInstance', 'entity', 'Lobby'];

    function LobbyEraseController($uibModalInstance, entity, Lobby) {
        var vm = this;

        vm.lobby = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Lobby.erase({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
