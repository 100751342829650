(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('LobbyController', LobbyController);

    LobbyController.$inject = ['$scope', '$state', 'Lobby', 'Tag', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal'];

    function LobbyController ($scope, $state, Lobby, Tag, ParseLinks, AlertService, pagingParams, paginationConstants, Principal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.isDeleteVisible = isDeleteVisible;
        vm.isAdmin = true;
        vm.account = null;
        vm.selectedLobby = null;
        vm.tags = Tag.queryNoPagination();
        vm.selectLobby = selectLobby;
        vm.selectLobbyIndex = 0;
        vm.quickApproval = quickApproval;

        loadAll();

        function loadAll () {
            Lobby.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate == 'newReg.lastName') {
                    result.push('newReg.firstName' + ',' + (vm.reverse ? 'asc' : 'desc'));
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.lobbies = data;
                vm.page = pagingParams.page;

                if (vm.lobbies) {
                    var lastIdx = vm.lobbies.length - 1;
                    vm.selectLobbyIndex = vm.selectLobbyIndex > lastIdx ? lastIdx : vm.selectLobbyIndex;
                    vm.selectedLobby = vm.lobbies[vm.selectLobbyIndex];
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.transition();
        }

        function isDeleteVisible(login) {
            return vm.isAdmin || vm.account.login == login;
        }

        function selectLobby(lobby) {
            vm.selectedLobby = lobby;
        }

        function quickApproval () {
            vm.selectLobbyIndex = arrayObjectIndexOf(vm.lobbies, vm.selectedLobby.id, 'id');
            Lobby.approval(vm.selectedLobby, onSaveSuccess, onSaveError);

            function onSaveSuccess (result) {
                loadAll();
            }

            function onSaveError () {
                AlertService.error(error.data.message);
            }
        }

        function arrayObjectIndexOf(myArray, searchTerm, property) {
            for(var i = 0, len = myArray.length; i < len; i++) {
                if (myArray[i][property] === searchTerm) return i;
            }
            return 0;
        }

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        Principal.hasAuthority('ROLE_ADMIN')
            .then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });

    }
})();
