(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('ProjectListSelectionDialogController', ProjectListSelectionDialogController);

    ProjectListSelectionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'image', 'ProjectList', 'RegistrationProjectList'];

    function ProjectListSelectionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, image, ProjectList, RegistrationProjectList) {
        var vm = this;

        vm.image = image;
        vm.registrationProjectList = entity;
        vm.registrationProjectList.registration = image.registration;
        vm.registrationProjectList.selectedImageId = image.id;

        vm.clear = clear;
        vm.save = save;
        vm.projectlists = ProjectList.queryNoPagination();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            RegistrationProjectList.update(vm.registrationProjectList, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('cerebroApp:registrationProjectListUpdate', result);
            $uibModalInstance.close(result);
            //vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
