(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('image', {
            parent: 'registration',
            url: '/{registrationId}/images',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'cerebroApp.image.home.title'
            },
            views: {
                'content@base': {
                    templateUrl: 'app/entities/image/images.html',
                    controller: 'ImageController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                registration: ['$stateParams', 'Registration', function ($stateParams, Registration) {
                    return Registration.get({id: $stateParams.registrationId}).$promise;
                }],
                images: ['$stateParams', 'Image', function ($stateParams, Image) {
                    return Image.queryByRegistration({id: $stateParams.registrationId});
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('image');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('image.new', {
            parent: 'image',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/image/image-dialog.html',
                    controller: 'ImageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                image: null,
                                imageContentType: null,
                                imageOrder: 0,
                                id: null,
                                registration: {id: $stateParams.registrationId }
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('image', null, { reload: 'image' });
                }, function() {
                    $state.go('image');
                });
            }]
        })
        .state('image.delete', {
            parent: 'image',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/image/image-delete-dialog.html',
                    controller: 'ImageDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Image', function(Image) {
                            return Image.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('image', null, { reload: 'image' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('image.project-list-select', {
            parent: 'image',
            url: '/{id}/project-list-select',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/project-list/project-list-select-dialog.html',
                    controller: 'ProjectListSelectionDialogController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                projectList: null,
                                selectedImageId: 0,
                                availability: 'NOT_SET',
                                status: 'NOT_SET',
                                registration: null
                            };
                        },
                        image: ['Image', function(Image) {
                            return Image.get({id : $stateParams.id}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('registrationProjectList');
                            $translatePartialLoader.addPart('projectList');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                        $state.go('image', null, { reload: 'image' });
                    }, function() {
                        $state.go('^');
                    });
            }]
        });

    }

})();
