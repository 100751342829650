(function() {
    'use strict';
    angular
        .module('cerebroApp')
        .factory('Lobby', Lobby);

    Lobby.$inject = ['$resource', 'DateUtils'];

    function Lobby ($resource, DateUtils) {
        var resourceUrl =  'api/lobbies/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.created = DateUtils.convertDateTimeFromServer(data.created);
                        data.newReg.birthDate = DateUtils.convertLocalDateFromServer(data.newReg.birthDate);
                        if (data.oldReg) {
                            data.oldReg.birthDate = DateUtils.convertLocalDateFromServer(data.oldReg.birthDate);
                        }
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'erase': { method:'DELETE', url: 'api/lobbies/erase/:id' },
            'approval': { method:'PUT', url: 'api/lobbies/approval' }
        });
    }
})();
