(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .factory('RegistrationFormSearch', RegistrationFormSearch);

    RegistrationFormSearch.$inject = ['$resource'];

    function RegistrationFormSearch($resource) {
        var resourceUrl =  'api/_search/registration-forms/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
