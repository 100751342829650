(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .directive('dateValidation', dateValidation);

    function dateValidation() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, ngModel) {
            var validate = function (birthYear, birthMonth, birthDay) {
                var modelValue = birthYear + "-" + birthMonth + "-" + birthDay;
                // console.log('modelValue: ' + modelValue);
                if (birthYear && birthMonth && birthDay && !moment(modelValue, ["YYYY-MM-DD"], true).isValid()) {
                    ngModel.$setValidity('validDate', false);
                }
                else {
                    ngModel.$setValidity('validDate', true);
                }
            };

            attrs.$observe('birthYear', function (value) {
                validate(value, attrs.birthMonth, attrs.birthDay);
            });

            attrs.$observe('birthMonth', function (value) {
                validate(attrs.birthYear, value, attrs.birthDay);
            });

            attrs.$observe('birthDay', function (value) {
                validate(attrs.birthYear, attrs.birthMonth, value);
            });

        }
    }
})();
