(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .config(uiBlockingConfig);

    uiBlockingConfig.$inject = ['blockUIConfig'];

    function uiBlockingConfig(blockUIConfig) {

        blockUIConfig.message = 'Kérem várjon...';
        blockUIConfig.delay = 500;
        //blockUIConfig.blockBrowserNavigation = true;
    }
})();