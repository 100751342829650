(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('RegistrationImageController', RegistrationImageController);

    RegistrationImageController.$inject = ['$scope', '$state', 'Image'];

    function RegistrationImageController ($scope, $state, Image) {
        var vm = this;

        vm.images = [];
        vm.imageContents = {};
        vm.loadAll = loadAll;
        vm.downloadImage = downloadImage;

        loadAll();

        function loadAll() {
            Image.queryByRegistration({id: $scope.$parent.registration.id}, function (result) {
                vm.images = result;
                if (vm.images.length > 4) {
                    vm.images.splice(4, vm.images.length - 4);
                }
                loadImages();
            });
        }

        function loadImages() {
            for (var i = 0; i  < vm.images.length; i++) {
                Image.get({id: vm.images[i].id}, function onSuccess(data) {
                    vm.imageContents[data.id] = data.image;
                });
            }
        }

        function downloadImage(imageId) {
            window.open('/api/images/download/' + imageId, '_blank');
        }

    }
})();
