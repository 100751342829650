(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .controller('RegistrationDialogController', RegistrationDialogController);

    RegistrationDialogController.$inject = ['$timeout', '$scope', '$state', 'entity', 'Registration',
        'City', 'Country', 'HairColor', 'EyeColor', 'Glass', 'Language', 'LanguageLevel', 'MusicInstrument', 'Sport', 'Tag', 'Dance', 'Race', 'Principal', 'previousState'];

    function RegistrationDialogController ($timeout, $scope, $state, entity, Registration,
           City, Country, HairColor, EyeColor, Glass, Language, LanguageLevel, MusicInstrument, Sport, Tag, Dance, Race, Principal, previousState) {
        var vm = this;

        vm.previousState = previousState;
        vm.registration = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.resetDisableableField = resetDisableableField;
        vm.cities = City.queryNoPagination();
        vm.countries = Country.queryNoPagination();
        vm.haircolors = HairColor.queryNoPagination();
        vm.eyecolors = EyeColor.queryNoPagination();
        vm.glasses = Glass.queryNoPagination();
        vm.languages = Language.queryNoPagination();
        vm.languagelevels = LanguageLevel.queryNoPagination();
        vm.musicinstruments = MusicInstrument.queryNoPagination();
        vm.sports = Sport.queryNoPagination();
        vm.tags = Tag.queryNoPagination();
        vm.dances = Dance.queryNoPagination();
        vm.races = Race.queryNoPagination();
        vm.isAdmin = true;
        vm.shoeSizePattern = '^0$|\\d+[-,/]*\\d+';
        vm.clothesSizePattern = '^0$|\\d+[-,/]*\\d+';
        vm.phonePattern = '[\\d]+';


        $timeout(function (){
            init();
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            if (vm.previousState.name.indexOf('lobby') !== -1) {
                $state.go('lobby', null, {reload: 'lobby'});
            }
            else {
                $state.go('registration', {scrollTo: 'reg-' + vm.registration.id}, { reload: 'registration'});
            }
        }

        function save () {
            vm.isSaving = true;
            if (vm.registration.id !== null) {
                vm.registration.lobby = !vm.isAdmin;
                Registration.update(vm.registration, onSaveSuccess, onSaveError);
            } else {
                Registration.save(vm.registration, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('cerebroApp:registrationUpdate', result);
            //vm.isSaving = false;
            if (vm.previousState.name.indexOf('lobby') !== -1) {
                $state.go('lobby', null, {reload: 'lobby'});
            }
            else {
                $state.go('registration', {scrollTo: 'reg-' + vm.registration.id}, {reload: 'registration'});
            }
        }

        function onSaveError () {
            vm.isSaving = false;
            $state.go('^');
        }

        vm.datePickerOpenStatus.birthDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function resetDisableableField(field, flag) {
            if (flag) {
                vm.registration[field] = null;
            }
        }

        function init () {
            if (!vm.registration.race) {
                var whiteRace = null;
                for (var i = 0; i < vm.races.length; i++) {
                    if (vm.races[i].name === 'fehér') {
                        vm.registration.race = vm.races[i];
                        break;
                    }
                }
            }
        }

        Principal.hasAuthority('ROLE_ADMIN')
            .then(function (result) {
                if (result) {
                    vm.isAdmin = true;
                } else {
                    vm.isAdmin = false;
                }
            });

    }
})();
