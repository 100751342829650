(function() {
    'use strict';

    angular
        .module('cerebroApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('registration-project-list', {
                parent: 'project-list',
                url: '/{projectListId}/registration/:scrollTo?rplpage&rplsort&rplsearch',
                data: {
                    authorities: ['ROLE_GUEST_USER', 'ROLE_USER', 'ROLE_CASTING_DIRECTOR', 'ROLE_CEREBRO_ADMIN', 'ROLE_ADMIN'],
                    pageTitle: 'cerebroApp.registrationProjectList.home.title'
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration-project-list/registration-project-lists.html',
                        controller: 'RegistrationProjectListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    rplpage: {
                        value: '1',
                        squash: true
                    },
                    rplsort: {
                        value: 'created,asc',
                        squash: true
                    },
                    rplsearch: null
                },
                onEnter: ['$location', '$stateParams', '$anchorScroll', '$timeout', function ($location, $stateParams, $anchorScroll, $timeout) {
                    $timeout(function() {
                        $location.hash($stateParams.scrollTo);
                        $anchorScroll()
                    }, 1000)
                }],
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.rplpage),
                            sort: $stateParams.rplsort,
                            predicate: PaginationUtil.parsePredicate($stateParams.rplsort),
                            ascending: PaginationUtil.parseAscending($stateParams.rplsort),
                            search: $stateParams.rplsearch
                        };
                    }],
                    projectList: ['$stateParams', 'ProjectList', function ($stateParams, ProjectList) {
                        return ProjectList.get({id: $stateParams.projectListId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registrationProjectList');
                        $translatePartialLoader.addPart('registration');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('projectListStatus');
                        $translatePartialLoader.addPart('projectListAvailability');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('registration-project-list-detail', {
                parent: 'registration-project-list',
                url: '/details/{id}',
                data: {
                    authorities: ['ROLE_GUEST_USER', 'ROLE_USER', 'ROLE_CASTING_DIRECTOR', 'ROLE_CEREBRO_ADMIN', 'ROLE_ADMIN'],
                    pageTitle: 'cerebroApp.registration.detail.title'
                },
                views: {
                    'content@base': {
                        templateUrl: 'app/entities/registration/registration-detail.html',
                        controller: 'RegistrationDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('registration');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('advertisement');
                        $translatePartialLoader.addPart('crowd');
                        $translatePartialLoader.addPart('roleDecision');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Registration', function($stateParams, Registration) {
                        return Registration.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'registration',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }],
                    backState: function() {
                        return {stateName: 'registration-project-list'};
                    }
                }
            })
            .state('registration-project-list-detail.project-list-select', {
                parent: 'registration-project-list-detail',
                url: '/{imageId}/project-list-select',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CASTING_DIRECTOR', 'ROLE_CEREBRO_ADMIN', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/project-list/project-list-select-dialog.html',
                        controller: 'ProjectListSelectionDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    projectList: null,
                                    selectedImageId: 0,
                                    availability: 'NOT_SET',
                                    status: 'NOT_SET',
                                    registration: null
                                };
                            },
                            image: ['Image', function (Image) {
                                return Image.get({id: $stateParams.imageId}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('registrationProjectList');
                                $translatePartialLoader.addPart('projectList');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                            $state.go('registration-project-list-detail');
                        }, function () {
                            $state.go('^');
                        });
                }]
            })
            .state('registration-project-list.delete', {
                parent: 'registration-project-list',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CASTING_DIRECTOR', 'ROLE_CEREBRO_ADMIN', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/registration-project-list/registration-project-list-delete-dialog.html',
                        controller: 'RegistrationProjectListDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['RegistrationProjectList', function(RegistrationProjectList) {
                                return RegistrationProjectList.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('registration-project-list', null, { reload: 'registration-project-list' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
