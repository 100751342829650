(function() {
    'use strict';
    angular
        .module('cerebroApp')
        .factory('Image', Image);

    Image.$inject = ['$resource'];

    function Image ($resource) {
        var resourceUrl =  'api/images/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryByRegistration': {
                method: 'GET',
                url: 'api/registration/:id/images',
                isArray: true
            },
            'queryWithContentByRegistration': {
                method: 'GET',
                url: 'api/registration/:id/images-with-content',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'reorder': {
                method: 'PUT',
                url: 'api/images/:id/reorder',
                params: {
                    id: '@id',
                    beforeId: '@beforeId'
                }
            }
        });
    }
})();
